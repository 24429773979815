// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { fa } from 'vuetify/iconsets/fa-svg'
//import { library } from '@fortawesome/fontawesome-svg-core'
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
//import * as components from 'vuetify/components'
//import * as directives from 'vuetify/directives'
import { VDatePicker } from 'vuetify/components/VDatePicker'
import { VImg } from 'vuetify/components/VImg'
import { VTooltip } from 'vuetify/components/VTooltip'
import { VTextField } from 'vuetify/components/VTextField'
import { VDialog } from 'vuetify/components/VDialog'
import { VSelect } from 'vuetify/components/VSelect'
import { VCheckbox } from 'vuetify/components/VCheckbox'
import { VTextarea } from 'vuetify/components/VTextarea'
import { VForm } from 'vuetify/components/VForm'
import { VRow } from 'vuetify/components/VGrid'
import { VProgressCircular } from 'vuetify/components/VProgressCircular'
import { VCardActions, VCardText } from 'vuetify/components/VCard'
import { VIcon } from 'vuetify/components/VIcon'
import { VListItemTitle, VListItem } from 'vuetify/components/VList'
import { VDivider} from 'vuetify/components/VDivider'
import { VList} from 'vuetify/components/VList'
import { VMenu} from 'vuetify/components/VMenu'
import { VAutocomplete } from 'vuetify/components/VAutocomplete'
import {VSkeletonLoader} from 'vuetify/components/VSkeletonLoader'
import {VNoSsr} from 'vuetify/components/VNoSsr'



import { VCard } from 'vuetify/components/VCard'
//import { VCardActions } from  'vuetify/lib/VCard/VCardActions'
import { VBtn } from 'vuetify/components/VBtn'
//import { VCardText } from 'vuetify/components/VCardText'
//import { VBtn } from 'vuetify/components'
//import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
//import "@fortawesome/fontawesome-free/css/all.css";


import { aliases, mdi } from "vuetify/lib/iconsets/mdi";

import { it, en } from 'vuetify/locale'

//library.add(fas) // Include needed solid icons
//library.add(far) // Include needed regular icons

import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";



export default createVuetify({
    ssr: import.meta.env.SSR,
    locale: {
        locale: 'it',
        fallback: 'en',
        messages: { it, en },
    },
    //components,
    components: {
        VDatePicker,
        VImg,
        VTooltip,
        VDialog,
        VCard,
        VCardActions,
        VBtn,
        VTextField,
        VSelect,
        VTextarea,
        VForm,
        VRow,
        VCardText,
        VProgressCircular,
        VIcon,
        VListItemTitle, VListItem ,
        VDivider,
        VList,
        VMenu,
        VAutocomplete,
        VSkeletonLoader,
        VNoSsr,
        VCheckbox

    },
    //directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
            fa,
            fas,
            far
        },
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#74b0be',
                secondary: '#2f3241',
                accent: '#2b2e3b',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
    },
})
