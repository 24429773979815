import Axios from "axios";

const axios = Axios.create({
  baseURL: `${import.meta.env.VITE_APP_API_HOST}:${import.meta.env.VITE_APP_API_PORT}/api/v1`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    responseType: "json",
  },
});

export default axios;
