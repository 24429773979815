export default {
state : {
  center: [0, 0],
  zoom: 13,
  tags: [],
  location: {},
  files: [],
  clicked:false
},

// getters
getters : {
  getFiles: (state) => {
    return state.files
  },
  getLocation: (state) => {
    return state.location
  },
  isClicked: (state) => {
    return state.clicked
  }
},

// actions
actions : {
  changeCenter({ commit }, coordinates) {
    commit("setCenter", coordinates);
  },
  changeZoom({ commit }, zoom) {
    commit("setZoom", zoom);
  },
  changeTags({ commit }, tags) {
    commit("setTags", tags);
  },
  changeLocation({ commit }, location) {
    commit("setLocation", location);
  },
  changeClicked({ commit }, clicked) {
    commit("setClicked", clicked);
  }
},

// mutations
mutations : {
  setCenter(state, coordinates) {
    state.center = coordinates;
  },
  setZoom(state, zoom) {
    state.zoom = zoom;
  },
  setTags(state, tags) {
    state.tags = tags;
  },
  setLocation(state, location) {
    state.location = location;
  },
  setFiles(state, files) {
    state.files = files;
  },
  setClicked(state, clicked) {
    state.clicked = clicked;
  }
},


};
