export default class Driver {

  constructor(newaxios) {
    this.axios = newaxios
  }

  async sendRequest(params) {
    const resp = await this.axios
      .post(`/sendRequest`, params)
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async shuffleAndPaginate(params) {
    const resp = await this.axios
      .get(`/hotels/shuffle-and-paginate`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async getHotels(params) {
    const resp = await this.axios
      .get(`/hotels`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async getFacilities(params) {
    const resp = await this.axios
      .post(`/hotels/facilities`, params)
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async allTagScores(params) {
    const resp = await this.axios
      .get(`/sentences/tags/scores/ranking`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async hotelStartsWith(query) {

    const resp = await this.axios
      .get(`/hotels/startsWith/` + query)
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async getMinimap(home, params) {
    const resp = await this.axios
      .get(`/hotels/${home.id}/minimap/`,{params:params})
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async getHotelsByLocation(params = null) {
    const resp = await this.axios
      .get(`/hotels/geo`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async getHotelsByLocationWithScores(params = null) {
    const resp = await this.axios
      .get(`/hotels/geo/scores`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async getHotelsByCityWithScores(params = null) {
    const resp = await this.axios
      .get(`/hotels/city/scores`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async cityTagScores(params = null) {
    const resp = await this.axios
      .get(`/hotels/city/tagScores`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }


  async getSentences(id, params) {
    const resp = await this.axios
      .get(`/hotels/${id}/sentences`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async tagBayes(params = null) {
    const resp = await this.axios
      .get(`/sentences/tags/scores`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async getHotel(id) {
    const resp = await this.axios.get(`/hotels/${id}`).catch((err) => {
      // eslint-disable-next-line
      console.warn(err);
      // this.loading = false;
      return null;
    });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async getImages(id, params) {
    const resp = await this.axios.get(`/admin/hotels/${id}/images`, { params: params }).catch((err) => {
      // eslint-disable-next-line
      console.warn(err);
      // this.loading = false;
      return null;
    });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async uploadImage(id, file) {
    const resp = await this.axios.post(`/admin/hotels/${id}/images`, file, {
      headers: {
        'Content-Type': 'multipart/form-data' // Set content type to multipart/form-data
      }
    }).catch((err) => {
      // eslint-disable-next-line
      console.warn(err);
      // this.loading = false;
      return null;
    });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async deleteImage(hotelid, id) {
    const resp = await this.axios.delete(`/admin/hotels/${hotelid}/images/${id}`).catch((err) => {
      // eslint-disable-next-line
      console.warn(err);
      // this.loading = false;
      return null;
    });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async deleteDuplicates(hotelid) {
    const resp = await this.axios.delete(`/admin/hotels/${hotelid}/images/duplicatedimages`).catch((err) => {
      // eslint-disable-next-line
      console.warn(err);
      // this.loading = false;
      return null;
    });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async updateMediaOrder(hotelid, orders) {
    const resp = await this.axios.post(`/admin/hotels/${hotelid}/images/updateMediaOrder`, { order: orders }).catch((err) => {
      // eslint-disable-next-line
      console.warn(err);
      // this.loading = false;
      return null;
    });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async botsolverScoreCity(params = null) {
    const resp = await this.axios
      .get(`/botsolverScore/city`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async botsolverScoreCityWithTags(params = null) {
    const resp = await this.axios
      .get(`/botsolverScore/city/withTags`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return err;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }


  async getHotelByCity(city) {
    const resp = await this.axios
      .get(`/hotels/city/${city}`)
      .catch((err) => {
        // eslint-disable-next-line
        console.warn(err);
        // this.loading = false;
        return err;
      });
    if (resp && resp.data) {
      return resp.data;
    }
    return []
  }
  async getComuni() {
    try {
      const resp = await this.axios.get(`/comune`);
      if (resp.data) {
        return resp.data;
      }
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        console.log("Request made and server responded");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log("The request was made but no response was received");
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error);
      }
      return null;
    }
  }

  async like(query) {
    console.log("query",query)
    const resp = await this.axios.get(`/hotels/like/${query}`).catch((err) => {
      // eslint-disable-next-line
      console.warn(err);
      // this.loading = false;
      return null;
    });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async startsWith(query) {
    try {
      const resp = await this.axios.get(`/comune/startsWith/${query}`);
      if (resp.data) {
        return resp.data;
      }
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        console.log("Request made and server responded");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log("The request was made but no response was received");
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error);
      }
      return null;
    }
  }

  async tagsRanking(params = null) {
    const resp = await this.axios
      .get(`/tagsScore/ranking`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async reviewsCount(params = null) {
    const resp = await this.axios
      .get(`/reviews/count`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async reviewsRating(params = null) {
    const resp = await this.axios
      .get(`/reviews/rating`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async countRating(params = null) {
    const resp = await this.axios
      .get(`/reviews/rating/count`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }
  async avgRatings(params = null) {
    const resp = await this.axios
      .get(`/reviews/rating/avg`, { params: params })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // this.loading = false;
        return null;
      });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async TagsCount(hotel_id) {

    const resp = await this.axios.get(`/hotels/${hotel_id}/tags/count`).catch((err) => {
      return null;
    });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async createHotelTags(hotel_id) {

    const resp = await this.axios.post(`/hotels/${hotel_id}/tags`).catch((err) => {
      return null;
    });
    if (resp && resp.data) {
      return resp.data;
    }
  }

  async Query(text) {
    try {
      const resp = await apiRequest.post(
        `/query`,
        { query: text },
        {
          "Content-Type": "application/json",
        }
      );
      if (resp && resp.data) {
        return resp.data;
      } else console.error("non ritorno data", resp);
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        console.log("Request made and server responded");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log("The request was made but no response was received");
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      return null;
    }
  }

  /*
    getHotels: async (params) => {
      try {
        const resp = await apiRequest.get(`/hotels`,{params:params});
        if (resp.data) {
          return resp.data;
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error);
        }
        return null;
      }
    },
    deleteHotel: async (id) => {
      try {
        const resp = await apiRequest.delete(`/hotels/${id}`);
        if (resp.data) {
          return resp.data;
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error);
        }
        return null;
      }
    },
    lastN: async (lastN, site) => {
      try {
        const resp = await apiRequest.get(
          `/hotelsLastN?site=${site}&lastN=${lastN}`
        );
        if (resp.data) {
          return resp.data;
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error);
        }
        return null;
      }
    },
    updateHotel: async (hotel_id, data) => {
      try {
        console.log("facciao update di ", hotel_id);
        const resp = await apiRequest.put(`/hotels/${hotel_id}`, data);
        if (resp && resp.data) {
          //console.log(resp)
          return resp.data;
        }
        return "si è verificaot un errore";
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error);
        }
        return null;
      }
    },
    getTags: async (review_id) => {
      const resp = await apiRequest
        .get(`/reviews/${review_id}/tags`)
        .catch((err) => {
          // eslint-disable-next-line
          console.warn(err);
          // this.loading = false;
          return null;
        });
      if (resp.data) {
        return resp.data;
      }
      return null;
    },
    getHotelTags: async (hotel_id) => {
      const resp = await apiRequest
        .get(`/hotel/${hotel_id}/tags`)
        .catch((err) => {
          // eslint-disable-next-line
          console.warn(err);
          // this.loading = false;
          return null;
        });
      if (resp.data) {
        return resp.data;
      }
      return null;
    },
    withoutOpinions: async () => {
      const resp = await apiRequest.get(`/hotelsWithoutOpinions`).catch((err) => {
        // eslint-disable-next-line
          console.warn(err);
        // this.loading = false;
        return null;
      });
      if (resp.data) {
        return resp.data;
      }
    },
    withoutReviews: async (site = null, shuffle = false) => {
      const resp = await apiRequest
        .get(
          `/hotelsWithoutReviews?${"shuffle=" + shuffle}${
            site ? "&site=" + site : ""
          }`
        )
        .catch((err) => {
          // eslint-disable-next-line
          console.warn(err);
          // this.loading = false;
          return null;
        });
      if (resp.data) {
        return resp.data;
      } else throw "error";
    },
    withReviews: async (site = null, shuffle = false) => {
      const resp = await apiRequest
        .get(
          `/hotelsWithReviews?${"shuffle=" + shuffle}${
            site ? "&site=" + site : ""
          }`
        )
        .catch((err) => {
          // eslint-disable-next-line
          console.warn(err);
          // this.loading = false;
          return null;
        });
      if (resp.data) {
        return resp.data;
      }
    },
    withoutTags: async () => {
      const resp = await apiRequest.get(`/hotelsWithoutTags`).catch((err) => {
        // eslint-disable-next-line
          console.warn(err);
        // this.loading = false;
        return null;
      });
      if (resp.data) {
        return resp.data;
      }
    },
    getReviews: async () => {
      const resp = await apiRequest.get(`/reviews`).catch((err) => {
        // eslint-disable-next-line
          console.warn(err);
        // this.loading = false;
        return null;
      });
      if (resp.data) {
        return resp.data;
      }
    },
    getHotelReviews: async (hotel_id, page = null, site = null) => {
      const resp = await apiRequest
        .get(
          `/hotel/${hotel_id}/reviews?${page ? "page=" + page : ""}${
            site ? "&site=" + site : ""
          }`
        )
        .catch((err) => {
          // eslint-disable-next-line
          console.warn(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    getReviewOpinion: async (review_id) => {
      const resp = await apiRequest
        .get(`/reviews/${review_id}/opinion`)
        .catch((err) => {
          // eslint-disable-next-line
          console.warn(err);
          // this.loading = false;
          return null;
        });
      if (resp.data) {
        return resp.data;
      }
      return null;
    },
    getHotelByName: async (name) => {
      const resp = await apiRequest
        .get(`/hotel/getByName/${name}`)
        .catch((err) => {
          // eslint-disable-next-line
          console.warn(err);
          // this.loading = false;
          return null;
        });
      if (resp.data) {
        return resp.data;
      }
    },

    getHotelByTown: async (name) => {
      const resp = await apiRequest
        .get(`/hotel/getByTown/${name}`)
        .catch((err) => {
          // eslint-disable-next-line
          console.warn(err);
          // this.loading = false;
          return null;
        });
      if (resp.data) {
        return resp.data;
      }
    },
    getHotelByTowns: async (data) => {
      const resp = await apiRequest
        .post(`/hotel/getByTowns`, data)
        .catch((err) => {
          // eslint-disable-next-line
          console.warn(err);
          // this.loading = false;
          return null;
        });
      if (resp.data) {
        return resp.data;
      }
    },
    insertHotelReviews: async (hotel_id, reviews, site) => {
      // console.log(apiRequest)
      try {
        const resp = await apiRequest.post(
          `/hotels/${hotel_id}/reviews/${site}/storeMultiple`,
          reviews
        );
        if (resp.data) {
          return resp.data;
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        return null;
      }
    },
    insertHotelTags: async (hotel_id, tags) => {
      // console.log(apiRequest)
      try {
        const resp = await apiRequest.post(
          `/hotels/${hotel_id}/storeMultipleTags`,
          tags
        );
        if (resp.data) {
          return resp.data;
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        return null;
      }
    },

    insertHotels: async (data) => {
      // console.log(apiRequest)
      try {
        const resp = await apiRequest.post(`/hotel/storeMultiple`, {
          hotels: data,
        });
        if (resp.data) {
          return resp.data;
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        return null;
      }
    },
    createHotelOpinions: async (hotel_id) => {
      // console.log(apiRequest)
      try {
        const resp = await apiRequest.post(`/hotels/${hotel_id}/opinions`, null, {
          "Content-Type": "application/json",
        });
        if (resp.data) {
          return resp.data;
        } else console.error("non ritorno data", resp);
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        return null;
      }
    },
    createOpinion: async (review) => {
      // console.log(apiRequest)
      try {
        const resp = await apiRequest.post(
          `/reviews/${review._id}/opinion`,
          review,
          {
            "Content-Type": "application/json",
          }
        );
        if (resp.data) {
          return resp.data;
        } else console.error("non ritorno data", resp);
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        return null;
      }
    },

    Query: async (text) => {
      try {
        const resp = await apiRequest.post(
          `/query`,
          { text: text },
          {
            "Content-Type": "application/json",
          }
        );
        if (resp && resp.data) {
          return resp.data;
        } else console.error("non ritorno data", resp);
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        return null;
      }
    },
    Sentencizer: async (text) => {
      try {
        const resp = await apiRequest["ai"].post(
          `/sentencizer`,
          { text: text },
          {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              "Content-Type": "application/json",
            },
          }
        );
        if (resp && resp.data) {
          return resp.data;
        } else console.error("non ritorno data", resp);
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        return null;
      }
    },
    getTmpHotel: async (id) => {
      const resp = await apiRequest.get(`/tmp_hotels/${id}`).catch((err) => {
        // eslint-disable-next-line
          console.warn(err);
        // this.loading = false;
        return null;
      });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    updateTmpHotel: async (hotel_id, data) => {
      try {
        console.log("faccio update di ", hotel_id);
        const resp = await apiRequest.post(`/tmp_hotels/${hotel_id}`, {
          data,
          _method: "patch",
        });
        if (resp && resp.data) {
          //console.log(resp)
          return resp.data;
        }
        return "si è verificato un errore";
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error);
        }
        return null;
      }
    },
    deleteTmpHotel: async (id) => {
      try {
        const resp = await apiRequest.delete(`/tmp_hotels/${id}`);
        if (resp.data) {
          return resp.data;
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error);
        }
        return null;
      }
    },


    createCommand: async ($params) => {
      try {
        const resp = await apiRequest.post(`/commands`, $params);
        if (resp.data) {
          return resp.data;
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("The request was made but no response was received");
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        return null;
      }
    },
    reviewsOverTime: async () => {
      const resp = await apiRequest.get(`/reviews/overtime`);
      if (resp && resp.data) {
        return resp.data;
      }
    },
    reviewsCount: async (params = null) => {
      const resp = await apiRequest
        .get(`/reviews/count`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    reviewsCountPost: async (params = null) => {
      const resp = await apiRequest
        .post(`/reviews/count`, params)
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    reviewsRating: async (params = null) => {
      const resp = await apiRequest
        .get(`/reviews/rating`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    countRating: async (params = null) => {
      const resp = await apiRequest
        .get(`/reviews/rating/count`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    avgRatings: async (params = null) => {
      const resp = await apiRequest
        .get(`/reviews/rating/avg`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    avgRatingsPost: async (params = null) => {
      const resp = await apiRequest
        .post(`/reviews/rating/avg`, params)
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    botsolverScore: async (params = null) => {
      const resp = await apiRequest
        .get(`/botsolverScore`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },

    botsolverScoreCompare: async (params = null) => {
      const resp = await apiRequest
        .get(`/botsolverScore/compare`, params)
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    polarityScoreCompare: async (params = null) => {
      const resp = await apiRequest
        .get(`/polarityScore/compare`, params)
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },

    tagsScoreCompare: async (params = null) => {
      const resp = await apiRequest
        .get(`/tagsScore/compare`, params)
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    tagsScoreMediumCompare: async (params = null) => {
      const resp = await apiRequest
        .post(`/tagsScore/medium`, params)
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    botsolverScoreCityPost: async (params = null) => {
      const resp = await apiRequest
        .post(`/botsolverScoreCity`, params)
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    sentencesSentiment: async (params = null) => {
      const resp = await apiRequest
        .get(`/sentences/sentiment`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    sentencesSentimentCity: async (params = null) => {
      const resp = await apiRequest
        .get(`/sentences/sentiment/city`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    sentenceTags: async (params = null) => {
      const resp = await apiRequest
        .get(`/sentences/tags`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },

    filteredTagBayes: async (params = null) => {
      const resp = await apiRequest
        .get(`/sentences/tags/scores`, params)
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },
    tagBayesCity: async (params = null) => {
      const resp = await apiRequest
        .get(`/sentences/tags/scores/city`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    },

    cityTags: async (params = null) => {
      const resp = await apiRequest
        .get(`/sentences/tags/city/long`, { params: params })
        .catch((err) => {
          // eslint-disable-next-line
        console.log(err);
          // this.loading = false;
          return null;
        });
      if (resp && resp.data) {
        return resp.data;
      }
    }, */
};
