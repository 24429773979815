const consentOptions = {
    current_lang: 'it',
    autoclear_cookies: false,                   // default: false
    page_scripts: false,                        // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: '',                      // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: true,                   // default: true
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once on the first accept/reject action
    },

    onAccept: function (cookie) {
        // callback triggered on the first accept/reject action, and after each page load
    },

    onChange: function (cookie, changed_categories) {
        // callback triggered when user changes preferences after consent has already been given
    },

    languages: {
        'it': {
            consent_modal: {
                title: 'I nostri cookies',
                description: 'Salve, questo sito utilizza cookie essenziali per garantirne il corretto funzionamento e cookie di tracciamento per capire come interagisci con esso. Quest\'ultimo verrà impostato solo previo consenso. <button type="button" data-cc="c-settings" class="cc-link">Lasciami scegliere</button>',
                primary_btn: {
                    text: 'Accetta tutto',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Rifiuta tutto',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie',
                save_settings_btn: 'Salva',
                accept_all_btn: 'Accetta',
                reject_all_btn: 'Rifiuta',
                close_btn_label: 'Chiudi',
                // cookie_table_caption: 'Cookie list',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Uso dei Cookie 📢',
                        description: 'Utilizzo i cookie per garantire le funzionalità di base del sito Web e per migliorare la tua esperienza online. Puoi scegliere di attivare/disattivare ogni categoria quando vuoi. Per maggiori dettagli relativi ai cookie e ad altri dati sensibili, ti invitiamo a leggere l\'<a href="/cookie_policy" class="cc-link">informativa sulla privacy</a> completa.'
                    }, {
                        title: 'Cookie strettamente necessari',
                        description: 'Questi cookie sono essenziali per il corretto funzionamento del mio sito web. Senza questi cookie il sito non funzionerebbe correttamente',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    },
/*                     {
                        title: 'Performance and Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                col4: 'description ...',
                            }
                        ]
                    },
                    {
                        title: 'Advertisement and Targeting cookies',
                        description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    },  */
                    {
                        title: 'Piu informazione',
                        description: 'Per qualsiasi domanda relativa alla nostra politica sui cookie e alle tue scelte, <a class="cc-link" href="/cookie_policy">Leggi qui</a>.',
                    }
                ]
            }
        }
    }
};
const config = {
    guiOptions: {
      consentModal: {
        layout: 'box inline',
        position: 'bottom right',
      },
      preferencesModal: {
        layout: 'box',
      },
    },
  
    onFirstConsent: () => {
      console.log('onFirstAction fired');
    },
  
    onConsent: () => {
      console.log('onConsent fired ...');
    },
  
    onChange: () => {
      console.log('onChange fired ...');
    },
  
    categories: {
      necessary: {
        readOnly: true,
        enabled: true,
      },
      analytics: {
        autoClear: {
          cookies: [
            {
              name: /^(_ga|_gid)/,
            },
          ],
        },
      },
      ads: {},
    },
  
    language: {
      default: 'en',
  
      translations: {
        en: {
          consentModal: {
            title: 'I nostri cookies',
            description: 'Salve, questo sito utilizza cookie essenziali per garantirne il corretto funzionamento e cookie di tracciamento per capire come interagisci con esso. Quest\'ultimo verrà impostato solo previo consenso. <button type="button" data-cc="c-settings" class="cc-link">Lasciami scegliere</button>',
            acceptAllBtn: 'Accetta tutto',
            acceptNecessaryBtn: 'Rifiuta tutto',
            showPreferencesBtn: 'Personalizza',
            closeIconLabel: 'Rifiuta tutto e chiudi',
            footer: `
                        <a href="#link">Privacy Policy</a>
                    `,
          },
          preferencesModal: {
            title: 'Cookie ',
            acceptAllBtn: 'Accetta tutto',
            acceptNecessaryBtn: 'Rifiuta tutto',
            savePreferencesBtn: 'Salva preferenze',
            sections: [
              {
                title: 'Uso dei Cookie 📢',
                description: 'Utilizzo i cookie per garantire le funzionalità di base del sito Web e per migliorare la tua esperienza online. Puoi scegliere di attivare/disattivare ogni categoria quando vuoi. Per maggiori dettagli relativi ai cookie e ad altri dati sensibili, ti invitiamo a leggere l\'<a href="/cookie_policy" class="cc-link">informativa sulla privacy</a> completa.'
            },
              {
                title: 'Cookie strettamente necessari',
                        description: 'Questi cookie sono essenziali per il corretto funzionamento del mio sito web. Senza questi cookie il sito non funzionerebbe correttamente',
              linkedCategory: 'necessary',
              },
              {
                title: 'Performance e analytics',
                description:
                  'Ci servono per sapere se va tutto bene e come si comporta il sito.',
                linkedCategory: 'analytics',
                cookieTable: {
                  headers: {
                    name: 'Cookie',
                    domain: 'Dominio',
                    desc: 'Descrizione',
                  },
                  body: [
                    {
                      name: '_ga',
                      domain: 'airoomer.com',
                      desc: '',
                    },
                    {
                      name: '_gid',
                      domain: 'airoomer.com',
                      desc: '',
                    },
                  ],
                },
              },
/*               {
                title: 'Advertisement and targeting cookies',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                linkedCategory: 'ads',
              },
              {
                title: 'More information',
                description:
                  'For any queries in relation to our policy on cookies and your choices, please <a class="cc__link" href="#yourdomain.com">contact me</a>.',
              }, */
            ],
          },
        },
      },
    },
  };
export {consentOptions, config};
