import './bootstrap';
import '../css/app.css';

import { createApp, h, createSSRApp } from 'vue';
import { createInertiaApp, Head, Link } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import store from '@/store/index.js';
import FontAwesomeIcon from "@/plugins/fontawesome";
import Vuetify from "@/plugins/vuetify";
import api from "@/plugins/api";
import Toasted from "@hoppscotch/vue-toasted"

import CookieConsentVue from './plugins/cookieconsentvue.js';
import VueGtag from "vue-gtag";
const appName = import.meta.env.VITE_APP_NAME || 'Airoomer';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createSSRApp({ render: () => h(App, props) })
            .component("FontAwesomeIcon", FontAwesomeIcon)
            .use(plugin)
            .use(store)
            .use(CookieConsentVue)
            .use(ZiggyVue)
            .use(Vuetify)
            .use(Toasted)
            .use(VueGtag, {
                config: {
                    id: 'G-XDH545WCK9'
                }
            })
            .component('Head', Head)
            .component('Link', Link);
        console.log("props!!!!", props)
        const { apiConfig } = props.initialPage.props;
        console.log("props!!!!", props.initialPage.props.apiConfig, "apiconfig", apiConfig)
        if (apiConfig) {
            api.axios.defaults.headers.common['Authorization'] = `Bearer ${apiConfig.token}`;
            //api.axios.defaults.withCredentials = false;
        }
        app.config.globalProperties.$api = api;
        app.mount(el);
        return app;

    },
    progress: {
        color: '#4B5563',
    },
});
