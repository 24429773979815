import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//import { fas } from '@fortawesome/free-solid-svg-icons'
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faClose} from "@fortawesome/free-solid-svg-icons/faClose";
import {faMap} from "@fortawesome/free-solid-svg-icons/faMap";

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
    //fas,
    faInfoCircle,faChevronLeft, faClose, faMap)

// Register the component globally
//Vue.component('font-awesome-icon', FontAwesomeIcon)

export default FontAwesomeIcon;

