import Driver from "../services/driver";
import axios from "@/services/api.config"


const axios2 = axios.create({
    baseURL: `${import.meta.env.VITE_APP_API_HOST}:${import.meta.env.VITE_APP_API_PORT}/api/v1`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        responseType: "json",
    },
});

axios2.defaults.withCredentials = true;
axios2.defaults.withXSRFToken = true;

const lib = Driver;

const driver = new lib(axios2)
export default driver;
